import { SORT_METHODS } from "src/constant/common";

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

const transformDataVersionsToOptions = (array) => {
  const result = [];

  array.map((item) =>
    result.push({
      label: item.name,
      value: item.name,
      execute_files: transformDataExecutionFileToOptions(item.execute_files),
    })
  );

  return result;
};

const transformDataExecutionFileToOptions = (array) => {
  const result = [];

  array.map((item) => result.push({ label: item.name, value: item.name }));

  return result;
};

const getFileName = (path) => {
  if (!path) return '';
  const regex = /\/?([^\/]+\.json|[^\/]+\.csv)(?:\?|$)/;
  const match = path.match(regex);
  const fileName = match ? match[1] : '';
  return fileName;
}
const getFileType = (path) => {
  if (!path) return '';
  return path.split(".").pop();
}

const uniqArray = (arr) => {
  const seen = new Set();
  const uniqueByDataField = arr.filter(item => {
    if (!seen.has(item.dataField)) {
      seen.add(item.dataField);
      return true;
    }
    return false;
  });
  return uniqueByDataField;
};

const handleScrollTo = (top, left, id) => {
  setTimeout(() => {
    let leftPosition = left;
    if (id) {
      const element = document.getElementById(id);
      leftPosition = parseInt(element?.getBoundingClientRect().top - left);
    }
    window.scrollTo(top,leftPosition);
  }, 500);
};

const sortData = (data, key, methods = SORT_METHODS.ASC) => {
  return data.sort(function (a, b) {
    let firstChar = a;
    let secondChar = b;
    if (methods === SORT_METHODS.DESC) {
      firstChar = b;
      secondChar = a;
    }
    return firstChar[key].localeCompare(secondChar[key]);
  });
};

const uuid = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c => (
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  ));
}

const extractIDsFromString = (str) => {
  if (!str) return ["", ""];
  const regex = /([^/]+)\/([^/]+)\.json/;
  const match = str.match(regex);
  if (match) {
    return [match[1], match[2]];
  } else {
    return ["", ""];
  }
}


const transformProcessDataToOptions = (array) => {
  const result = [];

  array.map((item) =>
    result.push({
      label: item.name,
      value: item.id,
    })
  );

  return result;
};

const getConfigFromId = (id, array, key) => {
  const result = array.filter(item => item.id === id);
  if (!result.length || !Object.keys(result[0]?.config).length) return {};
  const config = result[0].config?.[key] || {};
  
  return Object.keys(config).sort().reverse().map(key => {
    return {'name': key, 'type': config[key]};
  });
}

const getConfigFileName = (config) => {
  const outputPath = [];
  if (config?.no_wind_path) {
    outputPath.push(getFileName(config.no_wind_path))
  }
  if (config?.blow_path) {
    outputPath.push(getFileName(config.blow_path))
  }
  return outputPath.join(', ');
}

export {
  humanFileSize,
  transformDataVersionsToOptions,
  getFileName,
  getFileType,
  uniqArray,
  handleScrollTo,
  sortData,
  uuid,
  extractIDsFromString,
  transformProcessDataToOptions,
  getConfigFromId,
  getConfigFileName,
};
