import classNames from "classnames";
import React from "react";
import { useRecoilState } from "recoil";
import { sidebarState } from "src/recoil/atoms/sidebar";
import { Outlet } from "react-router-dom";
import TopBar from "src/components/Common/Layout/TopBar";
import SidebarMenu from "src/components/Common/Layout/SidebarMenu";

const MainLayout = ({ classCustom = "" }) => {
  const [sidebar] = useRecoilState(sidebarState);
  return (
    <div style={{ display: 'flex', height: '100%'}}>
      <SidebarMenu />
      <div id="wrapper">
        <TopBar />
        <div className={classNames("content-page py-3", classCustom, sidebar.collapsed ? 'm-l-80' : 'm-l-250')}>
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default MainLayout;
