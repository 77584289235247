import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

const FormInput = ({
  label,
  name,
  placeholder,
  classInput,
  classGroup,
  register,
  errors,
  required,
  requiredMessage,
  pattern,
  maxLength,
  maxLengthMessage,
  min,
  minMessage,
  type,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={classNames("form-group", classGroup)}>
      {label && <Label for={name}>{label}</Label>}

      <input
        id={name}
        placeholder={placeholder}
        className={classNames("form-control", classInput, {
          "border-danger": errors,
        })}
        onKeyPress={(event) => {
          if (type === 'number' && !/^-?\d*\.?\d*$/.test(event.key)) {
            event.preventDefault();
          }
        }}
        {...register(name, {
          ...(required && { required: requiredMessage || t("required") }),
          ...(pattern && {pattern: {...pattern}}),
          ...(min && {validate: {positive: v => parseFloat(v) > parseFloat(min) || minMessage}}),
          ...(maxLength && {maxLength: {value: maxLength, message: maxLengthMessage || t("name_maxlength")} }),
          onBlur: (e) => {
            const target = e.target;
            if (typeof target.value === 'string' && target.value) {
              target.value = target.value.trim();
            }
          }
        })}
      />

      {errors && <div className={`text-danger font-12 mt-1`}>{errors}</div>}
    </div>
  );
};

export default FormInput;
