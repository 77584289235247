import React, { useEffect } from "react";
import { Alert } from "reactstrap";
import { useRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { TOAST_DISPLAY_TIME } from "src/constant/common";

const Notifier = () => {

  const [alert, setAlert] = useRecoilState(alertState);

  let timeOut = null;
  useEffect(() => {
    if (alert.status) {
      timeOut = setTimeout(() => {
        onDismissAlert();
      }, TOAST_DISPLAY_TIME);
    }
  }, [alert]);

  useEffect(() => {
    const handleClick = (event) => {
      onDismissAlert();
    };
    window.addEventListener("click", handleClick); 

    return () => {
      clearTimeout(timeOut);
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const onDismissAlert = () => {
    setAlert((oldState) => ({...oldState, status: false }));
  };

  return (
    <div className="alert-wrap">
      <Alert
        color={alert?.type}
        isOpen={alert?.status}
        className="custom-toast text-break"
        toggle={onDismissAlert}
      >
        {alert?.message}
      </Alert>
    </div>
  );
};

export default Notifier;
