import React from "react";
import APP_ROUTES from "./routes";

import FlightSimulator from "src/pages/FlightSimulator";
import TestAnalysis from "src/pages/TestAnalysis";
import NewFlightSimulator from "src/pages/FlightSimulator/new";
import Login from "src/pages/Login";
import FlightSimulatorDetail from "src/pages/FlightSimulator/detail";
import Error404 from "src/pages/NotFound";
import Token from "src/pages/Token";
import AccessDenied from "src/pages/AccessDenied";
import MainLayout from "src/components/Common/Layout/MainLayout";


const Routes = [
  { 
    path: APP_ROUTES.HOME, 
    element: <MainLayout />, 
    children: [
      { index: true, element: <FlightSimulator /> },
      {
        path: APP_ROUTES.FLIGHT_NEW,
        element: <NewFlightSimulator />,
      },
      {
        path: APP_ROUTES.TEST_ANALYSIS_LIST,
        element: <TestAnalysis />,
      },
      {
        path: APP_ROUTES.FLIGHT_DUPLICATE_DETAIL,
        element: <FlightSimulatorDetail />,
      },
    ],
  },
  {
    path: APP_ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: APP_ROUTES.TOKEN_PAGE,
    element: <Token />,
  },
  {
    path: APP_ROUTES.ACCESS_DENIED,
    element: <AccessDenied />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
 
];;

export default Routes;
