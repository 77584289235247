import classNames from "classnames";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Label } from "reactstrap";

const FormSelect = ({
  label,
  name,
  placeholder,
  classInput,
  classGroup,
  options,
  control,
  defaultValue = null,
  errors,
  required,
  watch,
  requiredMessage,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={classNames("form-group", classGroup)}>
      <Label>{label}</Label>

      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={{
          ...(required && { required: requiredMessage || t("required") }),
        }}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            inputRef={ref}
            value={options?.find((c) => c.value === value) || ""}
            onChange={(val) => onChange(val.value)}
            options={options}
            placeholder={placeholder}
            className={classNames(classInput)}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: errors ? "#ff5c75" : "#ced4da",
              }),
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: '#adb5bd',
                };
              }
            }}
          />
        )}
      />

      {errors && <div className={`text-danger font-12 mt-1`}>{errors}</div>}
    </div>
  );
};

export default FormSelect;
