import Routes from "./routes/useRoutes";
import { useRoutes } from 'react-router-dom';

import "./scss/theme.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  const AppRoutes = useRoutes(Routes);

  return <>{AppRoutes}</>;
}

export default App;
