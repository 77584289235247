import classNames from "classnames";
import React from "react";
import { Label } from "reactstrap";

const FormTextarea = ({
  label,
  name,
  placeholder,
  classInput,
  classGroup,
  register,
  rows = 6,
}) => {
  return (
    <div className={classNames("form-group", classGroup)}>
      <Label for={name}>{label}</Label>
      <textarea
        id={name}
        placeholder={placeholder}
        className={classNames("form-control", classInput)}
        rows={rows}
        {...register(name)}
      />
    </div>
  );
};

export default FormTextarea;
