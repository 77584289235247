const CREATE_SIMULATOR = "v1/simulators";
const EDIT_SIMULATOR = (id) => `v1/simulators/${id}`;
const GET_SIMULATOR = "v1/simulators";
const SIMULATOR_DETAIL = (id) => `v1/simulators/${id}`;
const GET_SIMULATOR_OPTIONS = (type) => `v1/versions?type=${type}`;
const POST_PRESIGNED_URL = "v1/simulator-pre-signed-upload";
const GET_SERIES_INPUT = (id) => `v1/simulators/${id}/series-input`;
const GET_SERIES_DASHBOARD = (id) => `/v1/simulators/${id}/dashboards/main`;
const GET_MULTIPLE_OPT_DASHBOARD = (id) => `/v1/simulators/${id}/dashboards/multiple-optimization`;
const GET_MULTIPLE_DASHBOARD = (id) => `/v1/simulators/${id}/dashboards/main-multiple`;
const GET_URL_SIGN_IN = `v1/login`;
const GET_ME = `v1/user/me`;
const GET_TEST_ANALYSIS = "v1/test-analyses";
const GET_TEST_ANALYSIS_PROCESS = "v1/test-analysis-processes";
const CREATE_TEST_ANALYSIS = "v1/test-analyses";
const DELETE_TEST_ANALYSIS_ITEM = (id) => `v1/test-analysis-items/${id}`;
const POST_PRE_SIGNED_UPLOAD = `v1/pre-signed-upload`;
const POST_TEST_ANALYSIS_ITEM = `v1/test-analysis-items`;

export {
  CREATE_SIMULATOR,
  EDIT_SIMULATOR,
  POST_PRESIGNED_URL,
  GET_SIMULATOR_OPTIONS,
  SIMULATOR_DETAIL,
  GET_SIMULATOR,
  GET_SERIES_INPUT,
  GET_URL_SIGN_IN,
  GET_ME,
  GET_SERIES_DASHBOARD,
  GET_MULTIPLE_OPT_DASHBOARD,
  GET_MULTIPLE_DASHBOARD,
  GET_TEST_ANALYSIS,
  GET_TEST_ANALYSIS_PROCESS,
  CREATE_TEST_ANALYSIS,
  DELETE_TEST_ANALYSIS_ITEM,
  POST_PRE_SIGNED_UPLOAD,
  POST_TEST_ANALYSIS_ITEM,
};

