import React, { useState, useEffect } from "react";
import { 
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { getFileName } from "src/utils/common";
import { CsvToHtmlTable } from 'react-csv-to-table';
import { useTranslation } from "react-i18next";
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';


const ModalSerieFilesView = ({isOpen, serieData, fileData, onClose}) => {
  const { t } = useTranslation("flight-simulator");
  const [toggle, setToggle] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [scrollPositionById, setScrollPositionById] = useState({'1': 0, '2': 0});

  const isCSV = (fileName) => {
    return fileName?.indexOf('.csv') !== -1;
  };

  const closeModal = () => {
    onClose();
    setToggle(false);
    setTimeout(() => {
      document.activeElement.blur();
    }, 500);
  }

  const isCSVContent = (content) => {
    if (typeof content !== 'string') {
        return false;
    }
    const csvPattern = /^(?:[^,\n]+,)*[^,\n]+(?:\n|$)/;
    return csvPattern.test(content);
  }

  const showJsonContent = (jsonObj) => {
    if(typeof jsonObj === 'string' && jsonObj.trim() === '') {
      return <div/>
    }
    if (typeof jsonObj !== 'object') {
      return <div>{t('file_error')}</div>
    }
    return <JsonView data={jsonObj} shouldExpandNode={allExpanded} style={{...defaultStyles, container: 'bg-white'}}/>
  }

  const getScrollPosition = () => {
    const div = document.getElementById('tabContentModal');
    return div.scrollTop;
  }

  useEffect(() => {
    const element = document.getElementById('tabContentModal');
    if (element) {
      element.scrollTop = scrollPositionById[activeTab];
    }
  }, [activeTab]);

  useEffect(() => {
    setToggle(isOpen);
    setActiveTab(1);
    setScrollPositionById({'1': 0, '2': 0});
  }, [isOpen]);

  return (
    <Modal 
      centered 
      isOpen={toggle} 
      toggle={closeModal} 
      className="modal-full-width"
    >
      <ModalHeader 
        className="p-b-0" 
        toggle={closeModal}
      ></ModalHeader>
      <ModalBody>
        <Nav tabs className="border-0">
        {
          fileData && (
            <NavItem className={`${activeTab === 1 ? 'active' : ''}`}>
              <NavLink
                role="button"
                onClick={() => {
                  setScrollPositionById({...scrollPositionById, [activeTab]: getScrollPosition()});
                  setActiveTab(1);
                }}
              >
                {getFileName(fileData.name)}
              </NavLink>
            </NavItem>
          )
        }
        {
          serieData?.fileResponseLink && (
            <NavItem className={`${activeTab === 2 ? 'active' : ''}`}>
              <NavLink
                role="button"
                onClick={() => {
                  setScrollPositionById({...scrollPositionById, [activeTab]: getScrollPosition()});
                  setActiveTab(2);
                }}
              >
                {getFileName(serieData?.fileResponseLink)}
              </NavLink>
            </NavItem>
          )
        }
        </Nav>
        <TabContent activeTab={activeTab} id="tabContentModal">
          {
            fileData && (
              <TabPane tabId={1}>
                {
                  isCSV(fileData.name) ? (
                    isCSVContent(fileData.fileContent) ? (
                      <CsvToHtmlTable
                        tableClassName="table table-striped table-hover"
                        data={fileData.fileContent}
                        csvDelimiter=","
                      />
                    ) : (
                      <div>{t('file_error')}</div>
                    )
                  ) : showJsonContent(fileData.fileContent)
                }
              </TabPane>
            )
          }
          {
            serieData?.fileResponseLink && (
              <TabPane tabId={2}>
                { showJsonContent(serieData?.fileResponse) }
              </TabPane>
            )
          }
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default ModalSerieFilesView;
