import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getPreSignedUrl, uploadFilesTos3, getPreSignedUrlTestAnalysis } from "src/services/upload";
import { Spinner } from "reactstrap";
import { getFileName, getFileType } from "src/utils/common";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { FILE_TYPE, UPLOAD_TYPE } from "src/constant/common";

const FormUpload = ({
  register,
  validate,
  errors,
  name,
  isMultiple = true,
  placeholder,
  classInput,
  classGroup,
  handleUpdateAttachments,
  files = [],
  uploadType,
  fileTypeAllow = [FILE_TYPE.JSON],
  fileTypeValidateMsg = 'new.validate_attachments',
  useNewApiUpload,
  onUpload
}) => {
  const { t } = useTranslation("flight-simulator");
  const [toggle, setToggle] = useState(false);
  const [itemDelete, setItemDelete] = useState(null);
  const [uploading, setUploading] = useState(false);
  const showAlert = useSetRecoilState(alertState);

  const acceptFiles = () => fileTypeAllow.map(item => `.${item}`).join();

  const handleUploadFile = async (e) => {
    if (e?.target?.files?.length > 10) {
      showAlert({
        status: true,
        message: t("new.limit_attachments"),
        type: "danger",
      });
      return;
    }

    const fileData = [];

    [...e?.target?.files].map((item) => {
      const fileType = getFileType(item.name);
      if (fileTypeAllow?.length && fileTypeAllow.indexOf(fileType) === -1) {
        return showAlert({
          status: true,
          message: t(fileTypeValidateMsg, {
            file_name: item.name,
          }),
          type: "danger",
        });
      }
      if (files.some((file) => getFileName(file) === item.name)) {
        return showAlert({
          status: true,
          message: t("new.validate_duplicate_file_name"),
          type: "danger",
        });
      }
      return fileData.push(item);
    });

    if (fileData.length > 0) {
      setUploading(true);
      onUpload(true);

      Promise.all([...fileData].map(uploadFile))
        .then((res) => {
          res = res.filter(item => item !== undefined);
          handleUpdateAttachments([...files, ...res]);
        })
        .finally(() => {
          onUpload(false);
          setUploading(false);
        });
    }
  };

  const uploadFile = async (file) => {
    if (!file.size) {
      showAlert({
        status: true,
        message: t("file_error"),
        type: "danger",
      });
      return;
    }
    let presignedUrl = {};
    const data = {
      file_name: file?.name,
      type: uploadType || UPLOAD_TYPE.RAW
    };
    if (useNewApiUpload) {
      presignedUrl = await getPreSignedUrlTestAnalysis({data});
    } else {
      presignedUrl = await getPreSignedUrl({data});
    }

    const { url, fields } = presignedUrl;
    return await uploadFilesTos3({
      url,
      fields,
      file,
    });
  };

  const handleDeleteAttachments = (index) => {
    const newFiles = files;
    newFiles.splice(index, 1);

    handleUpdateAttachments([...newFiles]);
    setItemDelete(null);
    setToggle(false);
  };

  return (
    <div className={classNames("form-group", classGroup)}>
      {files?.map((item, index) => (
        <div
          className="card shadow-none border width-by-percent-100 m-b-7"
          key={index}
        >
          <div className="p-2 d-flex justify-content-between align-items-center">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="avatar-sm">
                  <span className="avatar-title bg-soft-warning text-warning rounded fs-24">
                    <i className="bi bi-file-earmark-zip"></i>
                  </span>
                </div>
              </div>
              <div className="col ps-1">
                <p className="text-muted fw-bold fs-15 mb-0 text-break">
                  {getFileName(item)}
                </p>
              </div>
            </div>
            <div>
              <i
                className="bi bi-trash text-danger f-s-24 user-select-pointer"
                role="button"
                onClick={() => {
                  setToggle(true);
                  setItemDelete(index);
                }}
              />
            </div>
          </div>
        </div>
      ))}
      {uploading && (
        <div className="p-2 d-flex align-items-center justify-content-center">
          <Spinner color="secondary" />
        </div>
      )}
      <label
        htmlFor={name}
        className={`btn btn-secondary width-by-px-160 ${(uploading || (!isMultiple && files.length)) && 'disabled'}`}
      >
        {t("new.select_file")}
      </label>
      <input
        {...(register && register(name, {
          ...(validate && {validate: validate})
        }))}
        draggable
        multiple={isMultiple}
        type="file"
        name={name}
        id={name}
        hidden
        placeholder={placeholder}
        className={classNames("form-control", classInput)}
        onChange={handleUploadFile}
        accept={acceptFiles()}
        onClick={(event) => (event.currentTarget.value = null)}
        disabled={uploading || (!isMultiple && files.length)}
      />
      {errors?.[name] && <div className={`text-danger font-12 mt-1`}>{errors?.[name]?.message}</div>}

      <Modal isOpen={toggle} toggle={() => setToggle(false)} backdrop="static">
        <ModalHeader>{t("new.delete_modal.title_attachments")}</ModalHeader>
        <ModalBody>
          <div>{t("new.delete_modal.sure")}</div>
          <div>{t("new.delete_modal.recover")}</div>
        </ModalBody>
        <ModalFooter>
          <span
            className="btn btn-secondary btn-sm"
            role="button"
            onClick={() => setToggle(false)}
          >
            {t("new.delete_modal.cancel")}
          </span>
          <span
            className="btn btn-danger btn-sm"
            role="button"
            onClick={() => handleDeleteAttachments(itemDelete)}
          >
            {t("new.delete_modal.delete")}
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FormUpload;
