import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
  Spinner,
  Collapse, 
  Button,
} from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getSimulators,
  getSeriesInput,
  getContentFromLink,
  getDashboardLink,
  getMultipleOptDashboardLink,
  getMultipleDashboardLink,
} from "src/services/simulator-flight";
import { PAGINATION_DEFAULT, REFRESH_TIME } from "src/constant/common";
import SearchInput from "../../Common/SearchInput";
import classNames from "classnames";
import { getFileName, sortData, uniqArray, handleScrollTo, extractIDsFromString } from "src/utils/common";
import { PROCESS_STATUS, SIMULATION_RESULT, DASHBOARD_STATUS, RETRY_TIME, SIMULATION_TYPE, ERROR_CODE_BY_TYPE, SUB_TEXT_BY_ERROR_TYPE } from "src/constant/common";
import APP_ROUTES from "src/routes/routes";
import { useMutation, useQuery } from "src/Hooks/useReactQuery";
import LabelStatus from "src/components/Common/LabelStatus";
import SimulationDashboard from "src/components/Common/Dropdown/SimulationDashboard";
import { Tooltip } from "react-tooltip";
import LoadingWrapper from "../../Common/Table/Loading";
import ReactPaginate from "react-paginate";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';
import ModalSerieFilesView from "src/components/Common/Modal/ModalSerieFilesView";

const ShowDataCell = ({ data }) => {
  const MAX_HEIGHT_DISPLAY = 65;
  const ref = useRef();

  const [showMore, setShowMore] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);

  useEffect(() => {
    setShowMore(false);
    setIsShowMore(true);
    setTimeout(() => {
      if (ref?.current?.clientHeight > MAX_HEIGHT_DISPLAY) {
        setShowMore(true);
        setIsShowMore(false);
      }
    }, 0);
  }, [data]);

  return data ? (
    <>
      <div>
        <p
          className={classNames("mb-0 text-break-space", {
            "line-clamp-3 text-ellipsis height-by-px-60": !isShowMore,
          })}
          ref={ref}
        >
          {data}
        </p>
      </div>
      {showMore && (
        <span
          role="button"
          className="btn btn-link p-0 border-0 inline-block"
          onClick={() => {
            setIsShowMore(!isShowMore);
          }}
        >
          {isShowMore ? " Show less" : " Show more"}
        </span>
      )}
    </>
  ) : null;
};

const FlightSimulatorList = () => {
  const { t } = useTranslation(["flight-simulator","common"]);

  const showAlert = useSetRecoilState(alertState);
  
  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      stringifyOptions: {
        skipNull: true,
        skipEmptyString: true
      },
    }
  );

  const [textSearch, setTextSearch] = useState(params?.condition || null);

  const [seriesById, setSeriesById] = useState();
  const [isLoadingSimulator, setIsLoadingSimulator] = useState(true);
  const [simulationIdsOpen, setSimulationIdsOpen] = useState([]);
  const [simulationIsUpdated, setSimulationIsUpdated] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState({});
  const [allowScrollToId, setAllowScrollToId] = useState(true);
  const [dataModalFileView, setDataModalFileView] = useState({isOpen: false, serieData: {}, fileData: {}});


  const { data: dataSimulator, refetch } = useQuery(
    ["getSimulators"],
    () => getSimulators({
      page: params.page || 1,
      limit: PAGINATION_DEFAULT.pageSize,
      q: params?.condition,
    }),
    {
      onSuccess: (data) => {
        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }
        for (const simulationItem of data.items) {
          if (simulationIdsOpen.some(id => id === simulationItem.id) &&
              simulationItem.status === PROCESS_STATUS.FAILED && 
              !simulationIsUpdated.some(id => id === simulationItem.id)
          ) {
            getSeriesDetail(simulationItem);
            setSimulationIsUpdated(oldVal => ([...oldVal, simulationItem.id]));
          }
        }
      },
      onSettled: () => {
        setIsLoadingSimulator(false);
      },
    }
  );
  const { mutate: getSeriesData } = useMutation(
    {
      mutationKey: (id) => `getSeries${id}`,
      mutationFn: (id) => getSeriesInput(id),
    },
    true
  );

  const columnsSeriesTable = [
    {
      dataField: "param_filename",
      text: "param_filename",
    },
    {
      dataField: "series",
      text: "series",
    },
  ];

  const convertDataSeries = (data) => {
    let columnsName = [];
    const dataSeries = data.map((series) => {
      const seriesItem = series.jsonObject?.default || {};
      const { param_filename, serie_file_name, series: seriesData, dashboard_url, dashboard_status, jsonObject } = series;
      let columnsData = {};

      Object.keys(seriesItem).forEach((field) => {
        columnsData = {
          ...columnsData,
          [field]:
            Array.isArray(seriesItem[field]?.value) &&
            seriesItem[field]?.value?.length > 0
              ? [...seriesItem[field]?.value].join(", ")
              : seriesItem[field]?.value,
        };

        columnsName.push({
          dataField: `${field}`,
          text: `${field} ${
            seriesItem[field]?.unit ? `(${seriesItem[field]?.unit})` : ""
          }`,
        });
      });
      
      return { 
        paramList: {param_filename, series: seriesData, ...columnsData}, 
        param_filename, 
        series: seriesData, 
        serie_file_name, 
        dashboard_url, 
        dashboard_status,
        name: serie_file_name,
        fileContent: jsonObject,
        errorList: series?.errors?.length ? getErrorDetail(series.errors, serie_file_name) : []
      };
    });

    return {
      dataSeries: sortData(sortData(dataSeries, "param_filename"), "series"),
      columnSeries: uniqArray([...columnsSeriesTable, ...uniqArray(columnsName)]),
    };
  };

  const getSeriesDetail = (simulationData) => {
    const { id, type } = simulationData;
    getSeriesData(id, {
      onSuccess: async (data) => {
        if (type === SIMULATION_TYPE["6DOF"]) {
          await setSerieDataFor6DoFType(simulationData, data);
          return;
        }
        const json = [];
        let countError = 0;
        await Promise.all(
          [...data].map((i) =>
            getContentFromLink(i?.link).then((res) => {
              json.push({
                param_filename: getFileName(i?.serie_file_name),
                series: i?.serie_name,
                serie_file_name: i?.serie_file_name,
                dashboard_url: i?.dashboard_url,
                dashboard_status: i?.dashboard_status,
                jsonObject: res.data,
                errors: i?.errors || []
              });
            }).catch(error => {
              countError += 1;
              showAlert({
                status: true,   
                message: t("file_error"), 
                type: 'danger',
              });
            }),
          )
        );
        if (countError === data.length) {
          setSeriesById(oldVal => ({
            ...oldVal,
            [id]: {
              data: oldVal?.[id]?.data || [],
              columns: oldVal?.[id]?.columns || [],
              loading: false,
              error: !!!oldVal?.[id]?.data?.length,
            },
          }));
          return;
        }
        const seriesData = convertDataSeries(json);
        setSeriesById(oldVal => ({
          ...oldVal,
          [id]: {
            data: seriesData.dataSeries,
            columns: seriesData.columnSeries,
            loading: false,
            error: false,
          },
        }));
      
      },
      onError: () => {
        setSeriesById(oldVal => ({
          ...oldVal,
          [id]: {
            data: oldVal?.[id]?.data || [],
            columns: oldVal?.[id]?.columns || [],
            loading: false,
            error: !!!oldVal?.[id]?.data?.length,
          },
        }));
      },
    });
  }

  const onClickCollapsed = (row) => {
    const id = row?.id;
    if (simulationIdsOpen.some(id => id === row.id)) {
      setSimulationIdsOpen(oldVal => ([...oldVal.filter(id => id !== row.id)]));
      return;
    }
    setSimulationIdsOpen(oldVal => ([...oldVal, row.id]));
    setSeriesById(oldVal => ({
      ...oldVal,
      [id]: {
        ...oldVal?.[id],
        loading: true,
      },
    }));
    getSeriesDetail(row);    
  };

  const getFieldFromErrorData = (errFields = []) => {
    let fields = errFields.filter(item => !String(item).includes('list[') && !String(item).includes('literal['));
    if (fields.some(field => Number.isInteger(field))) {
      fields = fields.map(field => Number.isInteger(field) ? `[${field}]` : field).join('.');
      fields = fields.replace(/\.\[/g, '[');
    } else {
      fields = fields.join('.');
    }
    return fields;
  }

  const getErrorDetail = (errList, fileName) => {
    if (!errList?.length) {
      return [];
    }
    let outputList = [];
    const groupByErrorCode = {};
    for (const errItem of errList) {
      const errCode = ERROR_CODE_BY_TYPE?.[errItem.type] || 'FE-0005';
      groupByErrorCode[errCode] = groupByErrorCode?.[errCode] || {[errItem.type]: []};
      groupByErrorCode[errCode][errItem.type] = groupByErrorCode?.[errCode]?.[errItem.type] || [];
      groupByErrorCode[errCode][errItem.type].push(errItem);
    }

    for (const errCode in groupByErrorCode) {
      let errMsgItem = '• ' + t(`error.${errCode}-title`);
      const errGroup = groupByErrorCode[errCode];

      switch (errCode) {
        case 'FE-0007':
          for (const type in errGroup) {
            for (const errItem of errGroup[type]) {
              const fieldName1 = getFieldFromErrorData(errItem?.fields?.[0]);
              const fieldName2 = getFieldFromErrorData(errItem?.fields?.[1]);
              errMsgItem += t(`error.${errCode}-content`, {field_name_1: fieldName1, field_name_2: fieldName2, file_name: getFileName(fileName)});
            }
          }
        break;
        default:
          for (const type in errGroup) {
            const fields = [...new Set(errGroup[type].map(item => getFieldFromErrorData(item.loc)))].join(', ');
            const descMsg = errGroup[type][0]?.msg || '';
            const min = errGroup[type][0]?.ctx?.min_length;
            const max = errGroup[type][0]?.ctx?.max_length;
            const limitVal = min ? `least \`${min}\`` : (max ? `most \`${max}\`` : 'least `1`');
            const subText = SUB_TEXT_BY_ERROR_TYPE[type] || '';
            errMsgItem += t(`error.${errCode}-content`, {field_name: fields, file_name: getFileName(fileName), detail_msg: descMsg, limit_value: limitVal, sub_text: subText});
          }
        break;
      }
      outputList.push(errMsgItem);
    }
    
    return outputList;
  }

  const setSerieDataFor6DoFType = async (simulationItem, serieData) => {
    let filesContent = [];
    let fileResponseContent = '';
    const fileList = serieData?.params || serieData?.inputs;
    await Promise.all(
      [...fileList].map((item, index) =>
        getContentFromLink(item?.link).then((res) => {
          if (item?.errors?.length) {
            item.errorList = getErrorDetail(item.errors, item.name);
          }
          item.fileContent = res.data;
          filesContent[index] = item;
        }).catch(error => {
          showAlert({
            status: true,   
            message: t("file_error"), 
            type: 'danger',
          });
        }),
      )
    );
    if (serieData?.response) {
      await getContentFromLink(serieData?.response?.link).then((res) => {
        fileResponseContent = res.data;
      }).catch(error => {
        showAlert({
          status: true,   
          message: t("file_error"), 
          type: 'danger',
        });
      });
    }
   
    

    setSeriesById({
      ...seriesById,
      [simulationItem.id]: {
        data: filesContent,
        fileResponse: fileResponseContent,
        fileResponseLink: serieData?.response?.link,
        loading: false,
        error: false
      },
    });
  }

  

  const onChangePage = (value) => {
    setParams({
      ...params,
      page: value,
    });
    handleScrollTo(0, 0);
  };

  const checkDashboardUrl = async (serieData, params, retryCount) => {
    try {
      const res = await getDashboardLink(params);
      if (res.data.status === DASHBOARD_STATUS.INPROGRESS) {
        if (retryCount === RETRY_TIME) {
          showAlert({
            status: true,   
            message: t("over_time"), 
            type: 'danger',
          });
          serieData.loading = false;
          setSeriesById({...seriesById});
          return;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
        retryCount += 1;
        await checkDashboardUrl(serieData, params, retryCount);
        return;
      }
      serieData.loading = false;
      serieData.dashboard_status = res.data.status;
      serieData.dashboard_url = res.data.url;
      setSeriesById({...seriesById});
      if (res.data.status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.url, '_blank');
      }
    } catch (e) {
      serieData.loading = false;
      setSeriesById({...seriesById});
      showAlert({
        status: true,   
        message: e?.response?.data?.error?.message || e?.response?.data?.message, 
        type: 'danger',
      });
    }
  }

  const checkMultipleOptDashboardUrl = async (simulationData, params, retryCount) => {
    try {
      const res = await getMultipleOptDashboardLink(params);
      if (res.data.status === DASHBOARD_STATUS.INPROGRESS) {
        if (retryCount === RETRY_TIME) {
          showAlert({
            status: true,   
            message: t("over_time"), 
            type: 'danger',
          });
          setDashboardLoading(oldVal => ({...oldVal, [simulationData.id]: false}));
          return;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
        retryCount += 1;
        await checkMultipleOptDashboardUrl(simulationData, params, retryCount);
        return;
      }
      simulationData.opt_dashboard_url = res.data.url;
      simulationData.opt_dashboard_status = res.data.status;
      setDashboardLoading(oldVal => ({...oldVal, [simulationData.id]: false}));
      if (res.data.status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.url, '_blank');
      }
    } catch (e) {
      setDashboardLoading(oldVal => ({...oldVal, [simulationData.id]: false}));
      showAlert({
        status: true,   
        message: e?.response?.data?.error?.message || e?.response?.data?.message, 
        type: 'danger',
      });
    }
  }

  const checkMultipleDashboardUrl = async (simulationData, params, retryCount) => {
    try {
      const res = await getMultipleDashboardLink(params);
      if (res.data.status === DASHBOARD_STATUS.INPROGRESS) {
        if (retryCount === RETRY_TIME) {
          showAlert({
            status: true,   
            message: t("over_time"), 
            type: 'danger',
          });
          setDashboardLoading(oldVal => ({...oldVal, [simulationData.id]: false}));
          return;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
        retryCount += 1;
        await checkMultipleDashboardUrl(simulationData, params, retryCount);
        return;
      }
      simulationData.multi_dashboard_url = res.data.url;
      simulationData.multi_dashboard_status = res.data.status;
      setDashboardLoading(oldVal => ({...oldVal, [simulationData.id]: false}));
      if (res.data.status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.url, '_blank');
      }
    } catch (e) {
      setDashboardLoading(oldVal => ({...oldVal, [simulationData.id]: false}));
      showAlert({
        status: true,   
        message: e?.response?.data?.error?.message || e?.response?.data?.message, 
        type: 'danger',
      });
    }
  }

  const handleClickDashboard = async (simulationData, serieData) => {
    if (!serieData) return;
    if (serieData.dashboard_status === DASHBOARD_STATUS.SUCCEEDED && serieData.dashboard_url) {
      window.open(serieData.dashboard_url, '_blank');
      return;
    }
    serieData.loading = true;
    const [serieId, serieName] = extractIDsFromString(serieData.serie_file_name);
    const data = {
      id: simulationData.id,
      params: {
        serie_id: serieId,
        serie_name: serieName
      }
    }
    setSeriesById(oldVal => ({...oldVal}));
    await checkDashboardUrl(serieData, data, 1);
  };

  const handleClickMultipleOptDashboard = async (simulationData) => {

    if (simulationData.opt_dashboard_status === DASHBOARD_STATUS.SUCCEEDED && simulationData.opt_dashboard_url) {
      window.open(simulationData.opt_dashboard_url, '_blank');
      return;
    }
    setDashboardLoading({...dashboardLoading, [simulationData.id]: true});
    const data = {
      id: simulationData.id,
    }
    await checkMultipleOptDashboardUrl(simulationData, data, 1);
  };

  const handleClickMultipleDashboard = async (simulationData) => {

    if (simulationData.multi_dashboard_status === DASHBOARD_STATUS.SUCCEEDED && simulationData.multi_dashboard_url) {
      window.open(simulationData.multi_dashboard_url, '_blank');
      return;
    }
    setDashboardLoading({...dashboardLoading, [simulationData.id]: true});
    const data = {
      id: simulationData.id,
    }
    await checkMultipleDashboardUrl(simulationData, data, 1);
  };

  const onKeyDownInput = (e) => {
    if (e.key === "Enter") {
      onSubmitSearch(e.target.value);
    }
  };

  const onSubmitSearch = (textSearch) => {
    if (params?.condition === textSearch && !isLoadingSimulator) {
      setIsLoadingSimulator(true);
      refetch();
    }
    setParams({
      page: 1,
      condition: textSearch,
    });
  };

  const getCurrentPage = () => {
    return (params?.page || PAGINATION_DEFAULT.page)-1;
  }
  
  useEffect(() => {
    setIsLoadingSimulator(true);
    refetch();
  }, [params]);

  useEffect(() => {
    let count = 0;
    const countDownToRefresh = setInterval(() => {
      if (document.hidden) {
        count = 0;
        return;
      }
      count++;
      if (count >= REFRESH_TIME) {
        refetch();
        count = 0;
      }
    }, 1000);
    return () => clearInterval(countDownToRefresh);
  }, []);

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
                <SearchInput
                  submit={onSubmitSearch}
                  setTextSearch={setTextSearch}
                  onKeyDownInput={onKeyDownInput}
                  textSearch={textSearch}
                  placeholder={t("list.search_placeholder")}
                />
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <Link
                          to={APP_ROUTES.FLIGHT_NEW}
                          className="btn btn-primary text-capitalize"
                        >
                          {t("list.redirect_new")}
                        </Link>
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <LoadingWrapper loading={isLoadingSimulator}>
                        <div className="react-bootstrap-table overflow-auto simulation-table">
                          <table className="table striped table-collapse table-fixed">
                            <thead>
                              <tr>
                                <th className="width-by-px-120">{t("list.table.id")}</th>
                                <th>{t("list.table.name")}</th>
                                <th className="width-by-px-130">{t("list.table.status")}</th>
                                <th className="width-by-px-80">{t("list.table.param")}</th>
                                <th className="width-by-px-80">{t("list.table.type")}</th>
                                <th>{t("list.table.version")}</th>
                                <th className="width-by-px-160">{t("list.table.execution_file")}</th>
                                <th>{t("list.table.user")}</th>
                                <th className="width-by-px-110">{t("list.table.update_at")}</th>
                                <th>{t("list.table.note")}</th>
                                <th className="width-by-px-60"></th>
                                <th className="width-by-px-60"></th>
                                <th className="width-by-px-60"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataSimulator?.items?.length > 0 ? (
                                dataSimulator.items.map((row, indexRow) => {
                                  return ([
                                    <tr key={indexRow} id={`row${row?.id}`}>
                                      <td>{row.id}</td>
                                      <td>{row.name}</td>
                                      <td><LabelStatus row={row} errorList={getErrorDetail(row?.validate_error?.errors, row?.validate_error?.name)} /></td>
                                      <td>
                                        <Button
                                          outline
                                          size="sm"
                                          className="px-1 btn-collapse height-by-px-36"
                                          onClick={() => onClickCollapsed(row)}
                                        >
                                          <i
                                            className={`bi bi-chevron-${
                                              simulationIdsOpen.includes(row.id) ? "down" : "up"
                                            } f-s-14 fw-bold text-dark`}
                                          />
                                        </Button>
                                      </td>
                                      <td>{row.type}</td>
                                      <td>{row.version_name}</td>
                                      <td>{row.execution_file_name}</td>
                                      <td>{row.updated_by}</td>
                                      <td>{row.updated_at}</td>
                                      <td><ShowDataCell data={row.note} /></td>
                                      <td className="text-center">
                                        {(row.status === PROCESS_STATUS.SUCCEEDED && 
                                          (row.execution_file_name === SIMULATION_RESULT.MULTI || row.execution_file_name === SIMULATION_RESULT.PAYLOAD) &&
                                          row.type === SIMULATION_TYPE["3DOF"] ) ? (
                                          dashboardLoading?.[row.id] ? (
                                            <Spinner size="sm" color="secondary" />
                                          ) : (
                                            <SimulationDashboard
                                              simulationData={row}
                                              onClickMultiParam={() => handleClickMultipleDashboard(row)}
                                              onClickMultiOpt={() => handleClickMultipleOptDashboard(row)}
                                            />
                                          )
                                        ) : null}
                                        {(row.status === PROCESS_STATUS.SUCCEEDED &&
                                          row.execution_file_name === SIMULATION_RESULT.SINGLE &&
                                          row.type === SIMULATION_TYPE["3DOF"]) ? (
                                          dashboardLoading?.[row.id] ? (
                                            <Spinner size="sm" color="secondary" />
                                          ) : (
                                            <>
                                              <button
                                                className="btn btn-blank p-0 border-0"
                                                onClick={() => handleClickMultipleDashboard(row)}
                                                data-tooltip-id={`dashboard-${row?.id}`}
                                                data-tooltip-content={t("list.table.dashboard")}
                                              >
                                                <i className={`bi bi-speedometer2 ${row.multi_dashboard_url ? 'text-success' : 'text-dark'} f-s-20`}/>
                                              </button>
                                              <Tooltip id={`dashboard-${row?.id}`} className="text-break-space" />
                                            </>
                                          )
                                        ) : null}
                                      </td>
                                      <td>
                                        {row.result_link ? (
                                          <div>
                                            <a 
                                              href={row.result_link}
                                              data-tooltip-id={`download-${row?.id}`}
                                              data-tooltip-content={t("list.table.download")}
                                            >
                                              <i className="bi bi-cloud-download f-s-20 text-dark" />
                                            </a>
                                            <Tooltip id={`download-${row?.id}`} className="text-break-space" />
                                          </div>
                                        ) : null}
                                      </td>
                                      <td>
                                        {
                                          row.status === PROCESS_STATUS.SUCCEEDED ? (
                                            <div>
                                              <Link 
                                                to={`/flight/${row?.id}?action=copy${params.page ? '&page='+params.page : ''}${params?.condition ? '&condition='+params.condition : ''}`}
                                                data-tooltip-id={`copy-${row?.id}`}
                                                data-tooltip-content={t("list.table.copy")}
                                              >
                                                <i className="bi bi-copy f-s-20 text-dark" />
                                              </Link>
                                              <Tooltip id={`copy-${row?.id}`} className="text-break-space" />
                                            </div>
                                          ) : (row.status === PROCESS_STATUS.FAILED) ? (
                                            <div>
                                              <Link 
                                                to={`/flight/${row?.id}?action=retry${params.page ? '&page='+params.page : ''}${params?.condition ? '&condition='+params.condition : ''}`}
                                                data-tooltip-id={`retry-${row?.id}`}
                                                data-tooltip-content={t("new.retry")}
                                              >
                                                <i className="bi bi-arrow-clockwise f-s-20 text-dark" />
                                              </Link>
                                              <Tooltip id={`retry-${row?.id}`} className="text-break-space" />
                                            </div>
                                          ) : null
                                        }
                                      </td>
                                    </tr>,
                                    <tr className="collapse-row" key={`sub-row-${indexRow}`}>
                                      <td colSpan={13} className="hiddenRow">
                                        <Collapse isOpen={simulationIdsOpen.includes(row.id)}>
                                        {
                                          seriesById?.[row?.id]?.error ? (
                                            <div className="p-2 d-flex align-items-center justify-content-center w-100 height-by-px-70">
                                              {t("common:network_error")}
                                            </div>
                                          ) : seriesById?.[row?.id]?.loading ? (
                                            <div className="p-2 d-flex align-items-center justify-content-center w-100">
                                              <Spinner color="secondary" />
                                            </div>
                                          ) : row.type === SIMULATION_TYPE["3DOF"] ? (
                                            <div className="react-bootstrap-table table-detail overflow-auto">
                                              <table className="table">
                                                <thead className="table-secondary">
                                                  <tr>
                                                    <th className="width-by-px-80"></th>
                                                    {seriesById?.[row?.id]?.columns?.map((col, indexCol) => (
                                                      <th key={indexCol}>{col.text}</th>
                                                    ))}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {
                                                    seriesById?.[row?.id]?.data?.map((serieRow, indexSerie) => (
                                                      <tr key={indexSerie}>
                                                        
                                                        <td>
                                                          <div className="d-flex gap-2 align-items-center min-height-by-px-30">
                                                            <>
                                                              <button
                                                                className="btn btn-blank border-0 p-0"
                                                                onClick={() => {
                                                                  setDataModalFileView({isOpen: true, serieData: seriesById?.[row?.id], fileData: serieRow});
                                                                }}
                                                                data-tooltip-id={`fileview-${row?.id}-${indexSerie}`}
                                                                data-tooltip-content={t("list.table.display_data")}
                                                              >
                                                                <i className="bi bi-file-earmark-text f-s-20 text-dark"></i>
                                                              </button>
                                                              <Tooltip id={`fileview-${row?.id}-${indexSerie}`} className="text-break-space" />
                                                            </>
                                                            {
                                                              serieRow?.errorList?.length ? (
                                                                <>
                                                                  <i 
                                                                    className="bi text-danger bi-exclamation-triangle f-s-16"
                                                                    data-tooltip-id={`serie-item-${row?.id}-${indexSerie}`}
                                                                    data-tooltip-content={serieRow.errorList.join('\n')}
                                                                  />
                                                                  <Tooltip id={`serie-item-${row?.id}-${indexSerie}`} className="text-break-space z-i-1009" />
                                                                </>
                                                              ) : ''
                                                            }
                                                            { 
                                                              serieRow?.loading ? (
                                                                <Spinner size="sm" color="secondary" />
                                                              ) : (row.status === PROCESS_STATUS.SUCCEEDED && !serieRow?.errorList?.length) ? (
                                                                <>
                                                                  <button 
                                                                    className="btn btn-blank border-0 p-0"
                                                                    onClick={(e) => handleClickDashboard(row, serieRow)}
                                                                    data-tooltip-id={`dashboard-${row?.id}-${indexSerie}`}
                                                                    data-tooltip-content={t("list.table.dashboard")}
                                                                  >
                                                                    <i className={`bi bi-speedometer2 f-s-20 ${serieRow.dashboard_url ? 'text-success' : 'text-dark'}`} />
                                                                  </button>
                                                                  <Tooltip id={`dashboard-${row?.id}-${indexSerie}`} className="text-break-space" />
                                                                </>
                                                              ) : null
                                                            }
                                                          </div>
                                                        </td>

                                                        {seriesById?.[row?.id]?.columns?.map((col, indexCol) => (
                                                          <td key={indexCol}>{serieRow.paramList[col.dataField]}</td>
                                                        ))}
                                                      </tr>
                                                    ))
                                                  }
                                                </tbody>
                                              </table>
                                            </div>
                                          ) : row.type === SIMULATION_TYPE["6DOF"] ? (
                                            <div className="react-bootstrap-table table-detail overflow-auto">
                                              <table className="table table-fixed">
                                                <thead className="table-secondary">
                                                  <tr>
                                                    <th className="width-by-px-80"></th>
                                                    <th>{t("list.table.input_data")}</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {
                                                    seriesById?.[row?.id]?.data?.map((file, fileIndex) => (
                                                      <tr key={fileIndex}>
                                                        <td>
                                                          <div className="d-flex gap-2 align-items-center">
                                                            <div className="d-flex align-items-center min-height-by-px-30">
                                                              <button
                                                                className="btn btn-blank border-0 p-0"
                                                                onClick={() => {
                                                                  setDataModalFileView({isOpen: true, serieData: seriesById?.[row?.id], fileData: file});
                                                                }}
                                                                data-tooltip-id={`fileview-${row?.id}-${fileIndex}`}
                                                                data-tooltip-content={t("list.table.display_data")}
                                                              >
                                                                <i className="bi bi-file-earmark-text f-s-20 text-dark"></i>
                                                              </button>
                                                              <Tooltip id={`fileview-${row?.id}-${fileIndex}`} className="text-break-space" />
                                                            </div>
                                                            {
                                                              file?.errorList?.length ? (
                                                                <>
                                                                  <i 
                                                                    className="bi text-danger bi-exclamation-triangle f-s-16 m-l-5"
                                                                    data-tooltip-id={`serie-item-${row?.id}-${fileIndex}`}
                                                                    data-tooltip-content={file.errorList.join('\n')}
                                                                  />
                                                                  <Tooltip id={`serie-item-${row?.id}-${fileIndex}`} className="text-break-space" />
                                                                </>
                                                              ) : ''
                                                            }
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {getFileName(file.name)} {seriesById?.[row?.id]?.fileResponseLink ? `, ${getFileName(seriesById?.[row?.id]?.fileResponseLink)}` : ''}
                                                        </td>
                                                      </tr>
                                                    ))
                                                  }
                                                </tbody>
                                              </table>
                                            </div>
                                          ) : null
                                        }
                                        </Collapse>
                                      </td>
                                    </tr>
                                  ])}
                                )
                              ) : (
                                <tr>
                                  <td className="text-center" colSpan="13">
                                    {!isLoadingSimulator && <span>{t("no_data", {ns: 'common'})}</span>}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <ModalSerieFilesView {...dataModalFileView} onClose={() => setDataModalFileView(oldVal => ({...oldVal, isOpen: false}))} />
                        </div>
                        {dataSimulator?.pagination?.total_page > 1 && (
                          <div className="pagination-custom">
                            <div className="fw-bold">
                              {dataSimulator?.pagination?.total > 0 &&
                                t("pagination", {
                                  first: getCurrentPage() * 50 + 1,
                                  last:
                                    dataSimulator?.pagination?.total < (getCurrentPage() + 1) * 50
                                      ? dataSimulator?.pagination?.total
                                      : (getCurrentPage() + 1) * 50,
                                  total: dataSimulator?.pagination?.total,
                                })
                              }
                            </div>
                            <ReactPaginate
                              nextLabel={">"}
                              onPageChange={(value) => {
                                onChangePage(value.selected + 1);
                              }}
                              {...{
                                pageRangeDisplayed: 5,
                                marginPagesDisplayed: 2,
                                pageCount: dataSimulator?.pagination?.total_page,
                                forcePage: getCurrentPage(),
                              }}
                              previousLabel={"<"}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel={"..."}
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </LoadingWrapper>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default FlightSimulatorList;
