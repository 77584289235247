import axios from "axios";
import api from "src/configs/api";
import {
  CREATE_SIMULATOR,
  EDIT_SIMULATOR,
  GET_SIMULATOR,
  GET_SERIES_INPUT,
  GET_SIMULATOR_OPTIONS,
  SIMULATOR_DETAIL,
  GET_SERIES_DASHBOARD,
  GET_MULTIPLE_OPT_DASHBOARD,
  GET_MULTIPLE_DASHBOARD,
} from "src/constant/endpoint";

const getSimulators = async (params) => {
  return await api
    .get(GET_SIMULATOR, { params })
    .then((response) => response?.data?.data);
};

const getSimulatorsDetail = async (id) => {
  return await api
    .get(SIMULATOR_DETAIL(id))
    .then((response) => response?.data?.data);
};

const createSimulator = ({ data }) =>
  api.post(CREATE_SIMULATOR, { data }).then((response) => response?.data);

const editSimulator = ({ data }) =>
  api.put(EDIT_SIMULATOR(data.id), { data }).then((response) => response?.data);

const getSimulatorOptions = async (type) =>
  await api.get(GET_SIMULATOR_OPTIONS(type)).then((response) => response?.data?.data);

const getSeriesInput = async (id) => {
  return await api
    .get(GET_SERIES_INPUT(id))
    .then((response) => response?.data?.data);
};

const getContentFromLink = async (link) => axios.get(link);

const getDashboardLink = async (data) => {
  return await api.post(GET_SERIES_DASHBOARD(data.id), {data: data.params});
}

const getMultipleOptDashboardLink = async (data) => {
  return await api.post(GET_MULTIPLE_OPT_DASHBOARD(data.id), {data});
}

const getMultipleDashboardLink = async (data) => {
  return await api.post(GET_MULTIPLE_DASHBOARD(data.id), {data});
}

export {
  createSimulator,
  editSimulator,
  getSimulatorOptions,
  getSimulators,
  getSimulatorsDetail,
  getSeriesInput,
  getContentFromLink,
  getDashboardLink,
  getMultipleOptDashboardLink,
  getMultipleDashboardLink
};
