import React, { useState } from "react";
import { 
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const SimulationDashboard = ({onClickMultiParam, onClickMultiOpt, simulationData}) => {
  const { t } = useTranslation("flight-simulator");

  const [dropdownOpen, setDropdownOpen] = useState(false);



  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="align-self-center"
        data-tooltip-id={`dashboard-${simulationData?.id}`}
        data-tooltip-content={t("list.table.dashboard")}
      >
        <DropdownToggle
          data-toggle="dropdown"
          tag="button"
          className="border-0 bg-transparent"
        >
          <i className={`bi bi-speedometer2 ${simulationData.opt_dashboard_url && simulationData.multi_dashboard_url ? 'text-success' : 'text-dark'} f-s-20`}/>
        </DropdownToggle>
        
        <DropdownMenu
          end={true}
          className="topbar-dropdown-menu profile-dropdown-items"
        >
          <div onClick={toggleDropdown}>
            <div
              role="button"
              className={`dropdown-item ${simulationData.multi_dashboard_url ? 'text-success' : 'text-dark'}`}
              onClick={onClickMultiParam}
            >
              <span>{t("list.table.multi_param_dashboard")}</span>
            </div>
            <div
              role="button"
              className={`dropdown-item ${simulationData.opt_dashboard_url ? 'text-success' : 'text-dark'}`}
              onClick={onClickMultiOpt}
            >
              <span>{t("list.table.multi_opt_dashboard")}</span>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
      <Tooltip id={`dashboard-${simulationData?.id}`} className="text-break-space" />
    </>
  );
};

export default SimulationDashboard;
