const APP_ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  TOKEN_PAGE: "/token/:token",
  FLIGHT_LIST: "/flight",
  TEST_ANALYSIS_LIST: "/test-analysis",
  FLIGHT_NEW: "/flight/new",
  FLIGHT_DUPLICATE_DETAIL: "/flight/:id",
  ACCESS_DENIED: "/access-denied",
};

export default APP_ROUTES;
