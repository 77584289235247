export const PAGINATION_DEFAULT = {
  page: 1,
  pageSize: 50,
};

export const PROCESS_STATUS = {
  SUBMITTED: "SUBMITTED",
  PENDING: "PENDING",
  RUNNABLE: "RUNNABLE",
  STARTING: "STARTING",
  RUNNING: "RUNNING",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  IN_PROGRESS: "IN_PROGRESS"
};

export const PROCESS_STATUS_NAME = {
  SUBMITTED: "SUBMITTED",
  PENDING: "PENDING",
  RUNNABLE: "RUNNABLE",
  STARTING: "STARTING",
  RUNNING: "RUNNING",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  IN_PROGRESS: "IN PROGRESS"
};

export const ALLOW_EDIT_STATUS = [
  PROCESS_STATUS.SUCCEEDED,
  PROCESS_STATUS.FAILED,
  PROCESS_STATUS.PENDING,
];

export const alertDefault = {
  status: false,
  message: "",
  type: "success",
};

export const UPLOAD_FAIL_TYPE = {
  LIMIT: "limit",
};

export const TOAST_DISPLAY_TIME = 15000;
export const RETRY_TIME = 10;

export const FILE_TYPE = {
  JSON: "json",
  CSV: "csv",
};

export const GET_LABEL_WITH_STATUS = {
  [PROCESS_STATUS.SUBMITTED]: "primary",
  [PROCESS_STATUS.RUNNING]: "info",
  [PROCESS_STATUS.SUCCEEDED]: "success",
  [PROCESS_STATUS.SUCCESSFUL]: "success",
  [PROCESS_STATUS.FAILED]: "danger",
  [PROCESS_STATUS.IN_PROGRESS]: "info",
};

export const LOADING_STATUS = {
  LOADING: "LOADING",
  AUTHENTICATED: "authenticated",
  UNAUTHENTICATED: "unauthenticated",
};

export const SORT_METHODS = {
  ASC: "ASC",
  DESC: "DESC",
};

export const FORM_TYPE = {
  EDIT: "retry",
  NEW: "new",
};

export const SIMULATION_RESULT = {
  SINGLE: "main.m",
  MULTI: "multiOptimization.m",
  PAYLOAD: "payloadOptimization.m",
};

export const UPLOAD_TYPE = {
  RAW: "raw_data",
  COEFFICIENT: "aerodynamic_coefficients_data",
  '6DOF': "simulation_6dof",
};

export const DASHBOARD_STATUS = {
  SUCCEEDED: "successful",
  INPROGRESS: "in_progress",
  FAILED: "failed",
};

export const STATUS_ERROR_CODE = {
  NOT_AUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const PATTERN_NUMBER = /^\d*\.?\d*$/;

export const PATTERN_NEGATIVE_NUMBER = /^-?\d*\.?\d*$/;

export const SIMULATION_TYPE = {
  '3DOF': "3DoF",
  '6DOF': "6DoF",
};

export const EXECUTION_FILE_TYPE = {
  MAIN: "calculateMassShape.m",
  MAIN_6DOF: "simulateFlightTrajectory6DoF.m",
};

export const ERROR_CODE_BY_TYPE = {
  'json_syntax_error': "FE-0001",
  'model_type': "FE-0002",
  'missing': "FE-0004",
  'type_error.float': "FE-0005",
  'type_error.int': "FE-0005",
  'type_error.string': "FE-0005",
  'string_type': "FE-0005",
  'int_parsing': "FE-0005",
  'float_parsing': "FE-0005",
  'float_type': "FE-0005",
  'int_type': "FE-0005",
  'type_error.list': "FE-0005",
  'list_type': "FE-0005",
  'literal_error': 'FE-0006',
  'required_ranges': 'FE-0006',
  'check_positive': 'FE-0006',
  'check_non_negative': 'FE-0006',
  'match_length': 'FE-0006',
  'match': 'FE-0007',
  'FE_0007': 'FE-0007',
  'too_short': 'FE-0008',
  'string_too_short': 'FE-0004',
  'too_long': 'FE-0008',
  'empty_data': 'FE-0010',
  'csv_syntax_error': 'FE-0010',
  'redundant_column': 'FE-0011',
  'missing_column': 'FE-0011',
  'order_mixup': 'FE-0011',
  'data_empty': 'FE-0010',
  'SJ_0001': 'SJ-0001',
  'SJ_0002': 'SJ-0002',
  'SJ_0003': 'FE-0006',
  'SJ_0004': 'FE-0006',
  'SE_0500': 'SE-0500',
  'SE_0501': 'SE-0501',
};

export const SUB_TEXT_BY_ERROR_TYPE = {
  redundant_column: 'redundant column',
  missing_column: 'missing column',
  order_mixup: 'incorrect column'
}

export const REFRESH_TIME = 15;

