import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
  Collapse, 
  Button,
} from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getTestAnalysis,
} from "src/services/test-analysis";
import { PAGINATION_DEFAULT, PROCESS_STATUS, ALLOW_EDIT_STATUS, REFRESH_TIME } from "src/constant/common";
import { handleScrollTo, getFileName } from "src/utils/common";
import { useQuery } from "src/Hooks/useReactQuery";
import LabelStatus from "src/components/Common/LabelStatus";
import { Tooltip } from "react-tooltip";
import LoadingWrapper from "../../Common/Table/Loading";
import ReactPaginate from "react-paginate";
import ModalDeleteCsv from "../../Common/Modal/ModalDeleteCsv";
import ModalAddTestAnalysis from "../../Common/Modal/ModalAddTestAnalysis";
import ModalAddTestAnalysisItem from "../../Common/Modal/ModalAddTestAnalysisItem";

const TestAnalysisList = () => {
  const { t } = useTranslation(['test-analysis','common']);
  
  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      parseOptions: {
        parseNumbers: true,
      },
      stringifyOptions: {
        skipNull: true,
      },
    }
  );

  const [isLoadingSimulator, setIsLoadingSimulator] = useState(true);
  const [testAnalysisIdsOpen, setTestAnalysisIdsOpen] = useState([]);
  const [allowScrollToId, setAllowScrollToId] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [removeData, setRemoveData] = useState();

  const { data: dataTestAnalysis, refetch } = useQuery(
    ["getTestAnalysis"],
    () => getTestAnalysis({
      page: params.page,
      limit: PAGINATION_DEFAULT.pageSize,
      q: params?.condition,
    }),
    {
      onSuccess: (data) => {
        for (const testItem of data.items) {
          testItem.items.sort((a,b) => (a.type > b.type) ? -1 : ((b.type > a.type) ? 1 : 0));
          if (testItem.analysis_status === PROCESS_STATUS.SUCCEEDED) {
            const haveFailedItem = testItem.items.some(item => item.error_message);
            testItem.analysis_status = haveFailedItem ? PROCESS_STATUS.FAILED : testItem.analysis_status;
          }
          if (!testItem.items.length) {
            testItem.analysis_status = PROCESS_STATUS.PENDING;
          }
        }
        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }
      },
      onSettled: () => {
        setIsLoadingSimulator(false);
      },
    }
  );

  const onClickCollapsed = (row) => {
    row.isCollapsed = !row.isCollapsed; 
    if (testAnalysisIdsOpen.some(id => id === row.id)) {
      setTestAnalysisIdsOpen(oldVal => ([...oldVal.filter(id => id !== row.id)]));
    } else {
      setTestAnalysisIdsOpen(oldVal => ([...oldVal, row.id]));
    }
  };

  const onChangePage = (value) => {
    setParams({
      ...params,
      page: value,
    });
    handleScrollTo(0, 0);
  };

  const getCurrentPage = () => {
    return (params?.page || PAGINATION_DEFAULT.page)-1;
  }

  const confirmDeleteFile = (data) => {
    setToggle(true);
    setRemoveData(data);
  }

  const checkConditionShowDashboardButton = (row) => {
    const haveValidItem = row.items.some(item => !item.error_message);
    return row.dashboard_url && (row.analysis_status === PROCESS_STATUS.SUCCEEDED || row.analysis_status === PROCESS_STATUS.FAILED) && haveValidItem;
  }

  const checkConditionShowDeleteItemButton = (rowItem) => {
    return (rowItem.result_path || rowItem.error_message || rowItem.calculated_path);
  }
  
  useEffect(() => {
    setIsLoadingSimulator(true);
    refetch();
  }, [params]);

  useEffect(() => {
    let count = 0;
    const countDownToRefresh = setInterval(() => {
      if (document.hidden) {
        count = 0;
        return;
      }
      count++;
      if (count >= REFRESH_TIME) {
        refetch();
        count = 0;
      }
    }, 1000);
    return () => clearInterval(countDownToRefresh);
  }, []);

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <ModalAddTestAnalysis onSuccess={refetch}/>
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <LoadingWrapper loading={isLoadingSimulator}>
                        <div className="react-bootstrap-table overflow-auto simulation-table">
                          <table className="table striped table-collapse table-fixed">
                            <thead>
                              <tr>
                                <th style={{width: '7%'}}>{t("list.table.id")}</th>
                                <th style={{width: '23%'}}>{t("list.table.name")}</th>
                                <th>{t("list.table.status")}</th>
                                <th>{t("list.table.process")}</th>
                                <th>{t("list.table.surface_area")}</th>
                                <th>{t("list.table.length")}</th>
                                <th style={{width: '11%'}}>{t("list.table.original_data")}</th>
                                <th style={{width: '9%'}}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataTestAnalysis?.items?.length > 0 ? (
                                dataTestAnalysis.items.map((row, indexRow) => {
                                  return ([
                                    <tr key={indexRow} id={`row${row?.id}`}>
                                      <td>{row.id}</td>
                                      <td>{row.name}</td>
                                      <td>
                                        <LabelStatus 
                                          row={row} 
                                          field={row.analysis_status === PROCESS_STATUS.SUCCEEDED ? 'dashboard_status' : 'analysis_status'}
                                          staticTooltip={row.error_message}
                                        />
                                      </td>
                                      <td>{row.process.name}</td>
                                      <td>{row.config.representative_surface_areas}</td>
                                      <td>{row.config.representative_length}</td>
                                      <td>
                                        <div className="d-flex justify-content-between align-items-center width-by-px-90">
                                          <span className="m-r-5">{row.items.length} {row.items.length > 1 ? 'datas' : 'data'}</span>
                                          {
                                            row.items.length ? (
                                              <Button
                                                outline
                                                size="sm"
                                                className="px-1 btn-collapse height-by-px-36"
                                                onClick={() => onClickCollapsed(row)}
                                              >
                                                <i
                                                  className={`bi bi-chevron-${
                                                    row.isCollapsed ? "up" : "down"
                                                  } f-s-14 fw-bold text-dark`}
                                                />
                                              </Button>
                                            ) : null
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex justify-content-between">
                                          
                                          {checkConditionShowDashboardButton(row) ? (
                                            <>
                                              <Link 
                                                to={row.dashboard_url}
                                                target="_blank"
                                                data-tooltip-id={`dashboard-${row?.id}`}
                                                data-tooltip-content={t("list.table.dashboard")}
                                              >
                                                <i className={`bi bi-speedometer2 f-s-20 text-success`} />
                                              </Link>
                                              <Tooltip id={`dashboard-${row?.id}`} className="text-break-space" />
                                            </>
                                          ) : <div/>}
                                          <ModalAddTestAnalysisItem 
                                            onSuccess={(taId) => {
                                              refetch();
                                              if (!testAnalysisIdsOpen.some(id => id === taId)) {
                                                setTestAnalysisIdsOpen(oldVal => ([...oldVal, taId]));
                                              }
                                            }}
                                            testAnalysisData={row}
                                          />
                                        </div>
                                      </td>
                                    </tr>,
                                    <tr className="collapse-row" key={`sub-row-${indexRow}`}>
                                      <td colSpan={8} className="hiddenRow">
                                        { row.items.length ? (
                                          <Collapse isOpen={testAnalysisIdsOpen.includes(row.id)}>
                                            <div className="react-bootstrap-table table-detail overflow-auto">
                                              <table className="table table-fixed">
                                                <thead className="table-secondary">
                                                  <tr>
                                                    <th style={{width: '7%'}}></th>
                                                    <th style={{width: '12.5%'}}>{t("list.table.attack_angle")}</th>
                                                    <th style={{width: '12.5%'}}>{t("list.table.roll_angle")}</th>
                                                    <th style={{width: '19%'}}>{t("list.table.no_wind_result")}</th>
                                                    <th style={{width: '19%'}}>{t("list.table.blow_result")}</th>
                                                    <th style={{width: '19%'}}>{t("list.table.coefficients_data")}</th>
                                                    <th></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  { 
                                                    row.items?.map((childItem, childIndex) => (
                                                      <tr key={childIndex}>
                                                        <td className="col-fixed"></td>
                                                        <td className="col-fixed">{childItem.config.attack_angle != null ? childItem.config.attack_angle : '-'}</td>
                                                        <td className="col-fixed">{childItem.config.roll_angle != null ? childItem.config.roll_angle : '-'}</td>
                                                        <td className="col-fixed">{getFileName(childItem.config?.no_wind_path) || '-'}</td>
                                                        <td className="col-fixed">{getFileName(childItem.config?.blow_path) || '-'}</td>
                                                        <td className="col-fixed">{getFileName(childItem?.calculated_path) || '-'}</td>
                                                        <td>
                                                          <div className="d-flex align-items-center justify-content-end min-height-by-px-30">
                                                            {(childItem.error_message && (childItem.result_path || childItem.error_message)) ? (
                                                              <>
                                                                <i 
                                                                  className="bi text-danger bi-exclamation-triangle f-s-16"
                                                                  data-tooltip-id={`ta-item-${row?.id}-${childIndex}`}
                                                                  data-tooltip-content={childItem.error_message}
                                                                />
                                                                <Tooltip id={`ta-item-${row?.id}-${childIndex}`} className="text-break-space" />
                                                              </>
                                                            ) : null}
                                                            {
                                                              checkConditionShowDeleteItemButton(childItem) && (
                                                                <>
                                                                  <button 
                                                                    className="btn btn-blank p-0 border-0 m-l-15"
                                                                    disabled={!ALLOW_EDIT_STATUS.includes(row.analysis_status)} 
                                                                    onClick={() => confirmDeleteFile(childItem)}
                                                                    data-tooltip-id={`ta-item-delete-${row?.id}-${childIndex}`}
                                                                    data-tooltip-content={t("new.delete_modal.delete")}
                                                                  >
                                                                    <i className="bi bi-trash text-danger f-s-20"/>
                                                                  </button>
                                                                  <Tooltip id={`ta-item-delete-${row?.id}-${childIndex}`} className="text-break-space" />
                                                                </>
                                                              )
                                                            }
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    ))
                                                  }
                                                </tbody>
                                              </table>
                                            </div>
                                          </Collapse>
                                        ) : null }
                                      </td>
                                    </tr>
                                  ])}
                                )
                              ) : (
                                <tr>
                                  <td className="text-center" colSpan="8">
                                    {!isLoadingSimulator && <span>{t("no_data", {ns: 'common'})}</span>}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {dataTestAnalysis?.pagination?.total_page > 1 && (
                          <div className="pagination-custom">
                            <div className="fw-bold">
                              {dataTestAnalysis?.pagination?.total > 0 &&
                                t("pagination", {
                                  first: getCurrentPage() * 50 + 1,
                                  last:
                                    dataTestAnalysis?.pagination?.total < (getCurrentPage() + 1) * 50
                                      ? dataTestAnalysis?.pagination?.total
                                      : (getCurrentPage() + 1) * 50,
                                  total: dataTestAnalysis?.pagination?.total,
                                })
                              }
                            </div>
                            <ReactPaginate
                              nextLabel={">"}
                              onPageChange={(value) => {
                                onChangePage(value.selected + 1);
                              }}
                              {...{
                                pageRangeDisplayed: 5,
                                marginPagesDisplayed: 2,
                                pageCount: dataTestAnalysis?.pagination?.total_page,
                                forcePage: getCurrentPage(),
                              }}
                              previousLabel={"<"}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel={"..."}
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </LoadingWrapper>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
      <ModalDeleteCsv toggle={toggle} closeModal={() => setToggle(false)} data={removeData} onDelete={refetch} />
    </Container>
  );
};

export default TestAnalysisList;
