import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Row,
  Container,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import FormInput from "../../Common/Form/FormInput";
import FormTextarea from "../../Common/Form/FormTextarea";
import FormSelect from "../../Common/Form/FormSelect";
import FormUpload from "../../Common/Form/FormUpload";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import APP_ROUTES from "src/routes/routes";
import { FORM_TYPE, SIMULATION_TYPE, EXECUTION_FILE_TYPE, UPLOAD_TYPE, FILE_TYPE } from "src/constant/common";
import { handleScrollTo, transformDataVersionsToOptions } from "src/utils/common";
import {
  getSimulatorOptions,
} from "src/services/simulator-flight";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';

export const FORM_NAME = {
  NAME: "name",
  VERSION: "version_name",
  TYPE: "type",
  EXECUTION: "execution_file_name",
  NOTE: "note",
  SERIES: "series",
  INPUTS: 'inputs',
  PARAMS: 'params',
  FILE_RESPONSE: 'response'
};

export const DEFAULT_DATA_PARAMS = {
  serie_name: "",
  serie_file_name: [],
};

const FlightSimulatorForm = ({ submitData, data, title, action }) => {
  const { t } = useTranslation("flight-simulator");
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [itemDelete, setItemDelete] = useState(null);
  const [executionFiles, setExecutionFiles] = useState([]);
  const [versions, setVersions] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [init, setInit] = useState(true);
  let [searchParams] = useSearchParams();
  const formAction = searchParams.get('action');
  const setAlert = useSetRecoilState(alertState);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: data,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "series",
  });

  const watchVersion = watch(FORM_NAME.VERSION);
  const watchType = watch(FORM_NAME.TYPE);
  const watchExecutionFile = watch(FORM_NAME.EXECUTION);
  const watchParams6dof = watch(FORM_NAME.PARAMS);
  const watchInputs6dof = watch(FORM_NAME.INPUTS);
  const watchFileResponse = watch(FORM_NAME.FILE_RESPONSE);

  const handleSubmitForm = (data) => {
    const type = data[FORM_NAME.TYPE];
    const executionFileType = data[FORM_NAME.EXECUTION];
    if (type === SIMULATION_TYPE["3DOF"] && verifySeriesFileName(data[FORM_NAME.SERIES])) {
      return;
    }
    if (type === SIMULATION_TYPE["3DOF"] && verifySeriesName(data[FORM_NAME.SERIES])) {
      return;
    }
    if (type === SIMULATION_TYPE["6DOF"] && executionFileType === EXECUTION_FILE_TYPE.MAIN) {
      data[FORM_NAME.SERIES] = {
        inputs: data[FORM_NAME.INPUTS]
      };
    }
    if (type === SIMULATION_TYPE["6DOF"] && executionFileType === EXECUTION_FILE_TYPE.MAIN_6DOF) {
      const responseFile = data[FORM_NAME.FILE_RESPONSE];
      data[FORM_NAME.SERIES] = {
        params: data[FORM_NAME.PARAMS]
      };
      if (responseFile) {
        data[FORM_NAME.SERIES].response = responseFile;
      }
    }
    setIsSubmit(true);
    return submitData({ data }, () => setIsSubmit(false));
  };

  const verifySeriesFileName = (series) => {
    let isErr = false;
    series?.forEach((item, index) => {
      if (item.serie_file_name?.length === 0) {
        setError(`series.${index}.serie_name`, {
          type: "attachments",
          message: t("new.required_attachments"),
        });
        return (isErr = true);
      }
    });
    return isErr;
  };

  const verifySeriesName = (series) => {
    let isErr = false;
    const seriesName = series.map(item => item.serie_name);
    const nameCount = seriesName.reduce((acc, name) => {
      acc[name] = (acc[name] || 0) + 1;
      return acc;
    }, {});
    const duplicateNameArr = Object.keys(nameCount).filter(key => nameCount[key] > 1);
    
    if (duplicateNameArr.length) {
      for (const [index, value] of series.entries()) {
        if (duplicateNameArr.includes(value.serie_name)) {
          setError(`series.${index}.serie_name`, {type: "duplicate", message: ' '});
        }
      }
      setTimeout(() => {
        setAlert({
          status: true,   
          message: t("new.validate_duplicate_series_name"), 
          type: 'danger',
        });
      }, 200);
      return true;
    }
    return isErr;
  };

  const handleAddNewParams = () => append(DEFAULT_DATA_PARAMS);

  const handleUpdateAttachments = (serie_file_name, index) => {
    update(index, {
      serie_name: watch(`series.${index}.serie_name`),
      serie_file_name: serie_file_name?.map((i) => i),
    });

    if (watch(`series.${index}.serie_name`)) {
      clearErrors(`series.${index}.serie_name`);
    }
  };

  const handleUpdateAttachmentsFile6dof = (files, fieldName, isSingleFile) => {
    if (isSingleFile) {
      setValue(fieldName, files[0]);
      return;
    }
    setValue(fieldName, files);
    if (watch(fieldName)) {
      clearErrors(fieldName);
    }
  }

  const handleDeleteParams = (index) => {
    remove(index);
    setToggle(false);
    setItemDelete(null);
  };

  useEffect(() => {
    if (!watchType) {
      return;
    }
    if (watchType === SIMULATION_TYPE["3DOF"] && !fields.length) {
      handleAddNewParams();
    }
    const getVersions = async () => {
      const response = await getSimulatorOptions(watchType);
      setVersions(transformDataVersionsToOptions(response || []));
      
    }
    getVersions();
  }, [watchType]);

  useEffect(() => {
    if (!versions?.length) {
      return;
    }
    setValue(FORM_NAME.VERSION, '');
    setExecutionFiles([]);
    if (init) {
      setValue(FORM_NAME.VERSION, data[FORM_NAME.VERSION]);
    }
  }, [versions]);

  useEffect(() => {
    if (!watchVersion || !versions?.length) {
      return;
    }
    const versionFromOption = versions.find((i) => i.value === watchVersion);
    if (!versionFromOption) {
      setValue(FORM_NAME.VERSION, "");
      setInit(false);
    }
    setValue(FORM_NAME.EXECUTION, "");
    setExecutionFiles(versionFromOption?.execute_files);
  }, [watchVersion, versions]);

  useEffect(() => {
    if (!executionFiles?.length || !init || !watchVersion) {
      return;
    }
    setValue(FORM_NAME.EXECUTION, data[FORM_NAME.EXECUTION]);
    setInit(false);
  }, [executionFiles]);
  
  useEffect(() => {
    const firstErrorId = Object.keys(errors).length ? Object.keys(errors)[0] : null;
    if (firstErrorId) {
      handleScrollTo(0, 90, firstErrorId);
    }
  }, [errors[FORM_NAME.VERSION], errors[FORM_NAME.EXECUTION]]);

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="header-title">{title || t("new.title")}</div>
              <Form className="p-a-15">
                <div className="row">
                  <div className="col-md-6">
                    <FormInput
                      register={register}
                      label={t("new.name")}
                      name={FORM_NAME.NAME}
                      placeholder={t("new.name_placeholder")}
                      classInput="width-by-percent-80"
                      errors={errors?.[FORM_NAME.NAME]?.message}
                      requiredMessage={t("new.name_required")}
                      required
                      maxLength={255}
                      maxLengthMessage={t("new.name_maxlength")}
                    />
                    <FormSelect
                      label={t("new.type")}
                      name={FORM_NAME.TYPE}
                      placeholder={t("new.type_placeholder")}
                      classInput="width-by-percent-80"
                      control={control}
                      errors={errors?.[FORM_NAME.TYPE]?.message}
                      required
                      options={Object.keys(SIMULATION_TYPE).map((key) => ({label: SIMULATION_TYPE[key], value: SIMULATION_TYPE[key]}))}
                      requiredMessage={t("new.type_required")}
                    />

                    <FormSelect
                      label={t("new.version")}
                      name={FORM_NAME.VERSION}
                      placeholder={t("new.version_placeholder")}
                      classInput="width-by-percent-80"
                      control={control}
                      errors={errors?.[FORM_NAME.VERSION]?.message}
                      required
                      options={versions}
                      requiredMessage={t("new.version_required")}
                    />

                    <FormSelect
                      label={t("new.execution")}
                      name={FORM_NAME.EXECUTION}
                      placeholder={t("new.execution_placeholder")}
                      classInput="width-by-percent-80"
                      control={control}
                      errors={errors?.[FORM_NAME.EXECUTION]?.message}
                      required
                      options={executionFiles}
                      watch={watch(FORM_NAME.EXECUTION)}
                      requiredMessage={t("new.execution_required")}
                    />

                    <FormTextarea
                      register={register}
                      label={t("new.note")}
                      name={FORM_NAME.NOTE}
                      placeholder={t("new.note_placeholder")}
                      classInput="width-by-percent-80"
                      rows={4}
                    />
                  </div>
                  {
                    watchType === SIMULATION_TYPE["3DOF"] ? (
                      <div className="col-md-6">
                        <Label>{t("new.params_series")}</Label>

                        {fields?.map((param, index) => (
                          <div className="d-flex gap-2" key={param.id}>
                            <div className="width-by-percent-80">
                              <FormInput
                                register={register}
                                errors={
                                  errors?.series?.[index]?.serie_name?.message
                                }
                                required
                                name={`series.${index}.serie_name`}
                                placeholder={t(
                                  "new.params_series_name_placeholder"
                                )}
                                classInput="width-by-percent-100"
                                classGroup="m-b-7"
                                requiredMessage={t(
                                  "new.params_series_name_required"
                                )}
                              />

                              <FormUpload
                                files={param.serie_file_name}
                                name={`series.${index}.serie_file_name`}
                                handleUpdateAttachments={(attachments) =>
                                  handleUpdateAttachments(attachments, index)
                                }
                                onUpload={setIsUpload}
                              />
                            </div>
                            {fields?.length > 1 && (
                              <div className="width-by-percent-20">
                                <i
                                  className="bi bi-trash text-danger f-s-24 user-select-pointer"
                                  role="button"
                                  onClick={() => {
                                    setToggle(true);
                                    setItemDelete(index);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ))}

                        <div className="d-flex justify-content-center width-by-percent-80">
                          <span
                            className="btn btn-secondary width-by-px-200 mb-3"
                            onClick={handleAddNewParams}
                          >
                            <span>{t("new.add_prams")}</span>
                          </span>
                        </div>
                      </div>
                    ) : null
                  }

                  {
                    (watchType === SIMULATION_TYPE["6DOF"] && watchExecutionFile === EXECUTION_FILE_TYPE.MAIN) ? (
                      <div className="col-md-6">
                        <Label>{t("new.input_series")}</Label>
                        <FormUpload
                          register={register}
                          validate={(files) => files.length > 0 || t('new.required_attachments')}
                          errors={errors}
                          files={watchInputs6dof}
                          name={FORM_NAME.INPUTS}
                          fileTypeAllow={[FILE_TYPE.JSON, FILE_TYPE.CSV]}
                          fileTypeValidateMsg="new.validate_json_csv_attachments"
                          uploadType={UPLOAD_TYPE["6DOF"]}
                          handleUpdateAttachments={(files) => handleUpdateAttachmentsFile6dof(files, FORM_NAME.INPUTS)}
                          useNewApiUpload
                          onUpload={setIsUpload}
                        />
                      </div>
                    ) : null
                  }

                  {
                    (watchType === SIMULATION_TYPE["6DOF"] && watchExecutionFile === EXECUTION_FILE_TYPE.MAIN_6DOF) ? (
                      <div className="col-md-6">
                        <Label>{t("new.params_series")}</Label>
                        <FormUpload
                          register={register}
                          validate={(files) => files.length > 0 || t('new.required_attachments')}
                          errors={errors}
                          files={watchParams6dof}
                          name={FORM_NAME.PARAMS}
                          uploadType={UPLOAD_TYPE["6DOF"]}
                          handleUpdateAttachments={(files) => handleUpdateAttachmentsFile6dof(files, FORM_NAME.PARAMS)}
                          useNewApiUpload
                          onUpload={setIsUpload}
                        />
                        <Label>{t("new.response_series")}</Label>
                        <FormUpload
                          files={watchFileResponse ? [watchFileResponse] : []}
                          isMultiple={false}
                          name={FORM_NAME.FILE_RESPONSE}
                          uploadType={UPLOAD_TYPE["6DOF"]}
                          handleUpdateAttachments={(files) => handleUpdateAttachmentsFile6dof(files, FORM_NAME.FILE_RESPONSE, true)}
                          useNewApiUpload
                          onUpload={setIsUpload}
                        />
                      </div>
                    ) : null
                  }
                  
                </div>

                <div className="d-flex justify-content-center gap-2">
                  <span
                    role="button"
                    className="btn btn-secondary width-by-px-200 height-by-px-38"
                    onClick={() => navigate(APP_ROUTES.HOME)}
                  >
                    <span>{t("new.cancel")}</span>
                  </span>
                  <button
                    className="btn btn-primary width-by-px-200 height-by-px-38"
                    onClick={handleSubmit((data) => handleSubmitForm(data))}
                    disabled={isSubmit || isUpload}
                  >
                    {isSubmit ? (
                      <Spinner size="sm" />
                    ) : (
                      <span>{formAction === FORM_TYPE.EDIT ? t("new.retry") : t("new.submit")}</span>
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Row>

      <Modal isOpen={toggle} toggle={() => setToggle(false)} backdrop="static">
        <ModalHeader>{t("new.delete_modal.title")}</ModalHeader>
        <ModalBody>
          <div>{t("new.delete_modal.sure")}</div>
          <div>{t("new.delete_modal.recover")}</div>
        </ModalBody>
        <ModalFooter>
          <span
            className="btn btn-secondary"
            role="button"
            onClick={() => setToggle(false)}
          >
            {t("new.delete_modal.cancel")}
          </span>
          <span
            className="btn btn-danger"
            role="button"
            onClick={() => handleDeleteParams(itemDelete)}
          >
            {t("new.delete_modal.delete")}
          </span>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default FlightSimulatorForm;
