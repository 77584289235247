import { useMutation } from "src/Hooks/useReactQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import routeGuard from "src/HOC/routeGuard";
import FormFlightSimulator, {
  FORM_NAME,
} from "src/components/FlightSimulator/form";
import APP_ROUTES from "src/routes/routes";
import {
  createSimulator,
  editSimulator,
  getSimulatorsDetail,
} from "src/services/simulator-flight";
import { FORM_TYPE, SIMULATION_TYPE } from "src/constant/common";

const FlightSimulatorDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("flight-simulator");
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const formAction = searchParams.get('action');
  const page = searchParams.get('page');
  const condition = searchParams.get('condition');

  const [isLoadingDetail, setIsLoadingDetail] = useState(true);

  const { data, mutate: getSimulatorDetailMutate } = useMutation({
    mutationFn: () => getSimulatorsDetail(id),
    onSettled: () => setIsLoadingDetail(false),
  });

  const createSimulatorMutate = useMutation({
    mutationFn: ({ data }) => createSimulator({ data }),
  });

  const editSimulatorMutate = useMutation({
    mutationFn: ({ data }) => editSimulator({ data }),
  });

  const submitData = ({ data }, callback) => {
    if (formAction === FORM_TYPE.EDIT) {
      data.id = id;
      return editSimulatorMutate.mutate(
        { data },
        {
          onSuccess: () => navigate(`${APP_ROUTES.HOME}?id=${id}${page ? '&page='+page : ''}${condition ? '&condition='+condition : ''}`),
          onSettled: () => callback(),
        }
      );
    }
    if (data.type === SIMULATION_TYPE["3DOF"]) {
      data.series = data.series.map((item) => {
        delete item?.dashboard_urls;
        return item;
      });
    }
    return createSimulatorMutate.mutate(
      { data },
      {
        onSuccess: () => navigate(APP_ROUTES.HOME),
        onSettled: () => callback(),
      }
    );
  }

  useEffect(() => {
    getSimulatorDetailMutate();
    window.onpopstate = () => {
      navigate(`${APP_ROUTES.HOME}?id=${id}${page ? '&page='+page : ''}${condition ? '&condition='+condition : ''}`)
    }
  }, []);

  const transformDetailData = (data) => {
    data[FORM_NAME.PARAMS] = data?.series?.params || [];
    data[FORM_NAME.INPUTS] = data?.series?.inputs || [];
    data[FORM_NAME.FILE_RESPONSE] = data?.series?.response || '';
    return {
      ...data,
      name: formAction === FORM_TYPE.EDIT ? data?.name : `Copy of ${data?.name}`,
    }
  };

  return (
    <>
      {!isLoadingDetail && data && (
        <FormFlightSimulator
          submitData={submitData}
          data={transformDetailData(data)}
          title={t("new.title_detail")}
        />
      )}
    </>
  );
};

export default routeGuard(FlightSimulatorDetail);
